import reactStringReplace from "react-string-replace";

export default (text, placeholders) => {
	let formatted = text;

	if (typeof text !== "string" || !text) {
		return formatted;
	}

	const matches = [];
	let match;
	const regex = /(<(\S+)>)/g;
	while ((match = regex.exec(text)) !== null) {
		matches.push(match);
	}

	for (let i = 0; i < matches.length; i++) {
		const placeholder = placeholders[matches[i][2]] || "-";
		formatted = reactStringReplace(
			formatted,
			matches[i][0],
			() => placeholder
		);
	}

	return formatted;
};
