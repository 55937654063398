import styled, { css } from "styled-components";

const SimpleMenuListItem = styled("li")`
	display: inline-block;
	color: ${({ theme: { colors } }) => colors.primary};
	& + & {
		&:before {
			content: "|";
			font-size: 0.75rem;
			line-height: 1rem;
			margin: 0 0.25rem;
			vertical-align: text-top;
		}
	}
	&:hover {
		text-decoration: underline;
	}
`;

const SimpleMenuList = styled("ul")`
	display: inline;
	margin: 0;
	padding: 0;
	list-style: none;
`;

const SimpleMenuTitle = styled("span")`
	display: inline;
	margin-right: 0.25rem;
	color: ${({ theme: { colors } }) => colors.grey};
	&::after {
		content: ":";
	}
	${({ size }) =>
		size &&
		css`
			font-size: ${size};
		`}
`;

const Nav = styled.nav`
	${SimpleMenuListItem} {
		${({ size }) =>
			size &&
			css`
				font-size: ${size};
				&:before:not(:first-child) {
					content: "|";
				}
				&:before {
					font-size: ${size};
				}
			`}
	}
`;

const SimpleMenu = ({ title, children, size }) => {
	return (
		<Nav size={size}>
			<SimpleMenuTitle size={size}>{title}</SimpleMenuTitle>
			{children}
		</Nav>
	);
};

SimpleMenu.List = SimpleMenuList;
SimpleMenu.Item = SimpleMenuListItem;

export default SimpleMenu;
